html {
	font-size: 16px;
	height: 100%;
	direction: ltr;
}

body {
	direction: ltr;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	-webkit-tap-highlight-color: transparent;
	-webkit-text-size-adjust: none;
	-ms-touch-action: manipulation;
	touch-action: manipulation;
	-webkit-font-feature-settings: "liga" 0;
	font-feature-settings: "liga" 0;
	height: 100%;
	overflow-y: scroll;
	position: relative;

	@media print {
		background: none;
	}
}

body *::-webkit-scrollbar {
	width: 6px;
	height: 6px;
	transition: .3s background;
}

body *::-webkit-scrollbar-thumb {
	background: $gray-400;
}
body *:hover::-webkit-scrollbar-thumb {
	background: $gray-500;
}
