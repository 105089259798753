.popover {
	filter: drop-shadow(0 1px 3px rgba(0, 0, 0, 0.1));


	&.bs-popover-top {
		margin-bottom: $popover-arrow-height + .125rem;
	}

	.arrow {
		margin-left: calc(.25rem + 2px);
	}
}