.highlight {
	.hll { background-color: #ffc; }
	.c { color: #999; }
	.k { color: #069; }
	.o { color: #555; }
	.cm { color: #999; }
	.cp { color: #099; }
	.c1 { color: #999; }
	.cs { color: #999; }
	.gd { background-color: #fcc; border: 1px solid #c00; }
	.ge { font-style: italic; }
	.gr { color: #f00; }
	.gh { color: #030; }
	.gi { background-color: #cfc; border: 1px solid #0c0; }
	.go { color: #aaa; }
	.gp { color: #009; }
	.gu { color: #030; }
	.gt { color: #9c6; }
	.kc { color: #069; }
	.kd { color: #069; }
	.kn { color: #069; }
	.kp { color: #069; }
	.kr { color: #069; }
	.kt { color: #078; }
	.m { color: #f60; }
	.s { color: #d44950; }
	.na { color: #4f9fcf; }
	.nb { color: #366; }
	.nc { color: #0a8; }
	.no { color: #360; }
	.nd { color: #99f; }
	.ni { color: #999; }
	.ne { color: #c00; }
	.nf { color: #c0f; }
	.nl { color: #99f; }
	.nn { color: #0cf; }
	.nt { color: #2f6f9f; }
	.nv { color: #033; }
	.ow { color: #000; }
	.w { color: #bbb; }
	.mf { color: #f60; }
	.mh { color: #f60; }
	.mi { color: #f60; }
	.mo { color: #f60; }
	.sb { color: #c30; }
	.sc { color: #c30; }
	.sd { font-style: italic; color: #c30; }
	.s2 { color: #c30; }
	.se { color: #c30; }
	.sh { color: #c30; }
	.si { color: #a00; }
	.sx { color: #c30; }
	.sr { color: #3aa; }
	.s1 { color: #c30; }
	.ss { color: #fc3; }
	.bp { color: #366; }
	.vc { color: #033; }
	.vg { color: #033; }
	.vi { color: #033; }
	.il { color: #f60; }

	.css .o,
	.css .o + .nt,
	.css .nt + .nt { color: #999; }

	.language-bash::before,
	.language-sh::before {
		color: #009;
		content: "$ ";
		user-select: none;
	}

	.language-powershell::before {
		color: #009;
		content: "PM> ";
		user-select: none;
	}
}