.display-1,
.display-2,
.display-3,
.display-4 {
	i {
		vertical-align: baseline;
		font-size: 0.815em;
	}
}

.text-inherit { color: inherit !important; }
.text-default { color: $body-color !important; }
.text-muted-dark { color: $text-muted-dark !important; }

.tracking-tight		{ letter-spacing: -0.05em !important; }
.tracking-normal	{ letter-spacing: 0 !important; }
.tracking-wide		{ letter-spacing: 0.05em !important; }

.leading-none	{ line-height: 1 !important; }
.leading-tight	{ line-height: 1.25 !important; }
.leading-normal	{ line-height: $line-height-base !important; }
.leading-loose	{ line-height: 2 !important; }

.font-weight-medium { font-weight: $font-weight-medium !important; }