.multiple_emails-container { 
	/*border:1px #ccc solid; */
	/*border-radius: 4px; */
	box-shadow: inset 0 1px 1px rgba(0,0,0,.075); 
	padding:0; margin: 0; cursor:text; width:100%; 
}

.multiple_emails-container input { 
	clear:both; 
	width:100%; 
	/*border:0; */
	outline: none; 
	margin-bottom:3px; 
	padding-left: 5px; 
	box-sizing: border-box;
}

.multiple_emails-container input{
	/*border: 0 !important;*/
}

.multiple_emails-container input.multiple_emails-error {	
	box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px red !important; 
	outline: thin auto red !important; 
}

.multiple_emails-container ul {	
	list-style-type:none; 
	padding-left: 0; 
}

.multiple_emails-email { 
	margin: 3px 5px 3px 5px; 
	padding: 3px 5px 3px 5px; 
	border:1px #BBD8FB solid;
	border-radius: 3px;
	background: #F3F7FD; 
}

.multiple_emails-close { 
	float:left; 
	margin:0 3px;
}
