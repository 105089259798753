@import url("https://fonts.googleapis.com/css2?family=Bodoni+Moda:wght@400;500;700&display=swap");


.ff-title {
  font-family: "Bodoni Moda", serif;
  line-height: 1;

  .bg-primary {
    line-height: 1.5;
  }
}

.fs-15 {
  font-size: 1.5rem;
}

.fs-9 {
  font-size: .9rem;
}

.fs-8 {
  font-size: .8rem;
}

.fs-7 {
  font-size: .7rem;
}

.display-9 {
  font-size: .85rem;
  font-weight: 300;
}

.lh-15 {
  line-height: 1.5;
}
