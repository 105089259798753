@import "@fortawesome/fontawesome-pro/scss/fontawesome";
@import "@fortawesome/fontawesome-pro/scss/light";

.no-underline{
  text-decoration: none !important;
}

.no-resize {
  resize: none;
}
.img-responsive{
  max-width: 100%;
}

label.state-invalid {
  background: none!important;
  padding-right: 0!important;
}

.z-2 {
  z-index: 2;
}

.p-corner-right {
  bottom: 0;
  right: 0;
}

.form-group.card.bg-grey {
  @include media-breakpoint-up("md") {
    min-height: 14rem;
  }
}

.bg-grey {
  background-color: rgba(39, 52, 135, .05);
}

.bg-none {
  background: none !important;
}

.multiple_emails-container {
  border: none !important;
  box-shadow: none;
}

i.square {
  display: inline-block;
  height: .7rem;
  width: .7rem;
  background-color: $primary;
  transform: rotate(45deg);

  &.square-2 {
    background-color: $azure;
  }

  &.square-3 {
    background-color: $red;
  }
}

.multiple_emails-email {
  border: 1px $blue solid;
}

.box-or {
  left: 50%;
  margin-left: -30px;
  top: 40%;
}

$cursors: pointer, crosshair, e-resize, move, help;
@each $cursor in $cursors {
  @each $breakpoint in map-keys($grid-breakpoints) {
    @include media-breakpoint-up($breakpoint) {
      $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

      .cursor#{$infix}-#{$cursor}  { cursor: $cursor !important; }
    }
  }
}

$positions: static, relative, absolute, fixed, sticky;
@each $position in $positions {
  @each $breakpoint in map-keys($grid-breakpoints) {
    @include media-breakpoint-up($breakpoint) {
      $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

      .position#{$infix}-#{$position}  { position: $position !important; }
    }
  }
}

$rounds: 5, 10, 15, 20, 25, 30;
@each $r in $rounds {
  @each $breakpoint in map-keys($grid-breakpoints) {
    @include media-breakpoint-up($breakpoint) {
      $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

      .rounded#{$infix}-#{$r}  { border-radius: $r * 1px !important; }
    }
  }
}

@each $breakpoint in map-keys($grid-breakpoints) {
  @include media-breakpoint-up($breakpoint) {
    $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

    .w#{$infix}-100  {
      width: 100% !important;
    }
    .w#{$infix}-auto  {
      width: auto !important;
    }
  }
}

@each $breakpoint in map-keys($grid-breakpoints) {
  @include media-breakpoint-up($breakpoint) {
    $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

    .overflow#{$infix}-hidden {
      overflow: hidden !important;
    }
  }
}

.noselect,
.no-select {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome and Opera */
}

.no-borders {
  border: 0 !important;
}

.tooltip-inner {
  border: 1px solid $primary;
}

.shape-with-bean {
  position: relative;
  span{
    position: relative;
  }
  //&:before {
  //  content: '';
  //  position: absolute;
  //  width: 100%;
  //  height: 51px;
  //  left: 0;
  //  top: 0;
  //  background: url($path-img + "/shapes/bean-yellow.png") center center no-repeat transparent;
  //}
}
.bg-shape-br {
  //&:before {
  //  content: '';
  //  position: absolute;
  //  bottom: 0;
  //  right: -10px;
  //  width: 200px;
  //  height: 167px;
  //  background: url($path-img + "/shapes/ondulations.png") left center no-repeat transparent;
  //}
}
@include media-breakpoint-up("md") {
  .bg-shape-top {
    //&:after {
    //  z-index: 0;
    //  content: '';
    //  position: absolute;
    //  top: 0;
    //  left: 0;
    //  width: 159px;
    //  height: 117px;
    //  background: url($path-img + "/shapes/shape-top.png") top left no-repeat transparent;
    //}
  }
}
body {

}

.container-steps {
  .col-step {
    * {
      color: $blueDisabled;
    }
    &.done {
      position: relative;

      &:first-of-type {
        &::before {
          left: 1.5rem !important;
        }
      }
    }
    &.active {
      * {
        color: $blue;
      }

      &::before {
        content: "";
        position: absolute;
        height: .7rem;
        width: .7rem;
        background-color: #00B9FF;
        transform: rotate(45deg);
        top: calc(50% - .4rem);
        left: 1rem;

        @include media-breakpoint-up("md") {
          left: -1.5rem;
        }
      }

      &::after {
        content: "";
        position: absolute;
        height: .2rem;
        background-color: $blue;
        bottom: -.15rem;
        left: 0;
        right: 0;
      }

      //.container-step:before {
      //  content: '';
      //  position: absolute;
      //  width: 100%;
      //  height: 100%;
      //  left: 0;
      //  top: 0;
      //  background: url($path-img + "/shapes/bean-sm@2x.png") left center no-repeat transparent;
      //  background-size: 40%;
      //}

      .container-step {
        padding-left: 1rem;

        @include media-breakpoint-up("md") {
          padding-left: 0;
        }
      }
    }
  }
}

.collapse:not(.show) {
  display: none !important;
}

.vjs-cloudinary-button {
  display: none !important;
}

.card-language-container {
  .card.active{
    border-color: $blue;
  }
}

.navbar {
  border-bottom: 2px solid #C1C1DA;

  a {
    font-weight: 500;
  }
}

.btn-pill.btn-lg {
  min-width: 220px;
}

.btn {
  font-family: $fontFamilyBold;
}

.font-bold {
  font-family: $fontFamilyBold;
}

.card {
  //min-height: 380px;
  box-shadow: 0px 6px 34px rgba(0, 0, 0, 0.45);
  min-height: 1px;
}

.card-auto {
  min-height: 0 !important;
}

.card-small {
  min-height: 0 !important;
  box-shadow: 0px 4px 13px rgba(0, 0, 0, 0.14) !important;
}

.cards-statistics {
  .text-open {
    position: relative;
    &::before {
      content: " ";
      background-color: #BEDC00;
      height: 2px;
      width: 30px;
      position: absolute;
      bottom: 0;
      left: 0;
    }
  }
  .text-click {
    position: relative;
    &::before {
      content: " ";
      background-color: #00BE87;
      height: 2px;
      width: 30px;
      position: absolute;
      bottom: 0;
      left: 0;
    }
  }
  .text-orange {
    position: relative;
    &::before {
      content: " ";
      background-color: #fd9644;
      height: 2px;
      width: 30px;
      position: absolute;
      bottom: 0;
      left: 0;
    }
  }
  .text-red {
    position: relative;
    &::before {
      content: " ";
      background-color: #cd201f;
      height: 2px;
      width: 30px;
      position: absolute;
      bottom: 0;
      left: 0;
    }
  }
}

.radius-mini {
  border-radius: $border-mini-radius !important;
}

.fg-emoji-picker {
  left: 2rem !important;

  @include media-breakpoint-up("md") {
    left: 0;
  }
}

.with-shapes {
  &:after,
  &:before {
    content: '';
    position: absolute;
    width: 20px;
    height: 20px;
    transform: rotate(45deg);
  }

  &:before {
    top: -20px;
    left: -40px;
    background: $azure;
  }

  &:after {
    bottom: -20px;
    right: -40px;
    background: $red;
  }
}

select {
  width: 50px;
  height: 34px;
  border: 1px solid $blue;
  margin: 0 10px;
  background: none !important;
  padding-left: 10px;
  padding-right: 0 !important;

  &.state-invalid {
    border-color: $red;
  }

  &:first-child {
    margin-left: 0;
  }
}

.inner-calendar {
  position: relative;

  &:before {
    @extend %fa-icon;
    @extend .fal;
    content: fa-content($fa-var-calendar);
    position: absolute;
    left: 10px;
    top: 12px;
  }
}

#confirm_schedule_date {
  border: 1px solid $blue !important;
  padding-left: 30px;

  &.state-invalid {
    border-color: $red !important;
  }
}
