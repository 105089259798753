/*-- Chart --*/
.c3 {
}

.c3 svg {
	font: 10px sans-serif;
	-webkit-tap-highlight-color: transparent;
	font-family: $font-family-base;
}

.c3 path,
.c3 line {
	fill: none;
	stroke: $border-color;
}

.c3 text {
	user-select: none;
	font-size: px2rem(12px);
}

.c3-legend-item-tile,
.c3-xgrid-focus,
.c3-ygrid,
.c3-event-rect,
.c3-bars path {
	shape-rendering: crispEdges;
}

.c3-chart-arc path {
	stroke: #fff;
}

.c3-chart-arc text {
	fill: #fff;
	font-size: 13px;
}

/*-- Axis --*/
/*-- Grid --*/
.c3-grid line {
	stroke: #f0f0f0;
}

.c3-grid text {
	fill: #aaa;
}

.c3-xgrid,
.c3-ygrid {
	stroke: #e6e6e6;
	stroke-dasharray: 2 4;
}

/*-- Text on Chart --*/
.c3-text {
	font-size: 12px;
}

.c3-text.c3-empty {
	fill: #808080;
	font-size: 2em;
}

/*-- Line --*/
.c3-line {
	stroke-width: 2px;
}

/*-- Point --*/
.c3-circle._expanded_ {
	stroke-width: 2px;
	stroke: white;
}

.c3-selected-circle {
	fill: white;
	stroke-width: 1.5px;
}

/*-- Bar --*/
.c3-bar {
	stroke-width: 0;
}

.c3-bar._expanded_ {
	fill-opacity: 1;
	fill-opacity: 0.75;
}

/*-- Focus --*/
.c3-target.c3-focused {
	opacity: 1;
}

.c3-target.c3-focused path.c3-line, .c3-target.c3-focused path.c3-step {
	stroke-width: 2px;
}

.c3-target.c3-defocused {
	opacity: 0.3 !important;
}

/*-- Region --*/
.c3-region {
	fill: steelblue;
	fill-opacity: .1;
}

/*-- Brush --*/
.c3-brush .extent {
	fill-opacity: .1;
}

/*-- Select - Drag --*/
/*-- Legend --*/
.c3-legend-item {

	text {
		fill: #545454;
		font-size: 14px;
	}
}

.c3-legend-item-hidden {
	opacity: 0.15;
}

.c3-legend-background {
	fill: transparent;
	stroke: lightgray;
	stroke-width: 0;
}

/*-- Title --*/
.c3-title {
	font: 14px sans-serif;
}

/*-- Tooltip --*/
.c3-tooltip-container {
	z-index: 10;
}

.c3-tooltip {
	border-collapse: collapse;
	border-spacing: 0;
	empty-cells: show;
	font-size: 11px;
	line-height: 1;
	font-weight: 700;
	color: #fff;
	border-radius: 3px;
	background: $gray-900;
	white-space: nowrap;
}

.c3-tooltip tr {
}

.c3-tooltip th {
	padding: 6px 6px;
	text-align: left;
}

.c3-tooltip td {
	padding: 4px 6px;
	font-weight: 400;
}

.c3-tooltip td > span {
	display: inline-block;
	width: 8px;
	height: 8px;
	margin-right: 8px;
	border-radius: 50%;
	vertical-align: baseline;
}

.c3-tooltip td.value {
	text-align: right;
}

/*-- Area --*/
.c3-area {
	stroke-width: 0;
	opacity: 0.1;
}

.c3-target-filled .c3-area {
	opacity: 1 !important;
}

/*-- Arc --*/
.c3-chart-arcs-title {
	dominant-baseline: middle;
	font-size: 1.3em;
}

.c3-chart-arcs .c3-chart-arcs-background {
	fill: #e0e0e0;
	stroke: none;
}

.c3-chart-arcs .c3-chart-arcs-gauge-unit {
	fill: #000;
	font-size: 16px;
}

.c3-chart-arcs .c3-chart-arcs-gauge-max {
	fill: #777;
}

.c3-chart-arcs .c3-chart-arcs-gauge-min {
	fill: #777;
}

.c3-chart-arc .c3-gauge-value {
	fill: #000;
	/*  font-size: 28px !important;*/
}

.c3-chart-arc.c3-target g path {
	opacity: 1;
}

.c3-chart-arc.c3-target.c3-focused g path {
	opacity: 1;
}

.c3-axis {
	fill: $text-muted;
}

//.c3-axis-y,
//.c3-axis-y2 {
//	.domain {
//		display: none;
//	}
//
//	.tick line {
//		display: none;
//	}
//}