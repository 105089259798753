.sparkline {
	display: inline-block;
	height: 2rem;
}

.jqstooltip {
	box-sizing: content-box;
	font-family: inherit !important;
	background: #333 !important;
	border: none !important;
	border-radius: 3px;
	font-size: 11px !important;
	font-weight: 700 !important;
	line-height: 1 !important;
	padding: 6px !important;

	.jqsfield {
		font: inherit !important;
	}
}