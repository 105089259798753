.example {
	padding: 1.5rem;
	border: 1px solid $border-color;
	border-radius: 3px 3px 0 0;
	font-size: px2rem(15px);
}

.example-bg {
	background: $body-bg;
}

.example + .highlight {
	border-top: none;
	margin-top: 0;
	border-radius: 0 0 3px 3px;
}

.highlight {
	margin: 1rem 0 2rem;
	border: 1px solid $border-color;
	border-radius: 3px;
	font-size: px2rem(15px);
	max-height: 40rem;
	overflow: auto;
	background: #fcfcfc;
}

.highlight pre {
	margin-bottom: 0;
	background-color: transparent;
}

.example-column {
	margin: 0 auto;

	>.card:last-of-type {
		margin-bottom: 0;
	}
}

.example-column-1 {
	max-width: 20rem;
}

.example-column-2 {
	max-width: 40rem;
}